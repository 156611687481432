import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import SocialLinks from "../components/social_links"

const AboutPage = () => (
  <Layout>
    <Seo title="Over ons" />
    <section className="page-header">
      <h1>Over ons</h1>
    </section>    
    <section className="page-intro">
      <div className="page-intro-content-rows">
        <h3>Een optiekzaak als geen ander</h3>
        <p>Optiek De Leersnijder is een gerenommeerde optiekzaak in hartje Wuustwezel. Onze missie is simpel: de beste oogzorg bieden voor jong en oud. We nemen de tijd om elke klant persoonlijk te begeleiden. Samen vinden we de beste oplossing voor jouw ogen. </p>
        <p>Alles begint met een nauwkeurige oogmeting. Met de nieuwste apparaten meten onze gediplomeerde opticiens en optometristen jouw ogen. Na een korte analyse gaan we samen op zoek naar het montuur dat bij jou en je levensstijl past. Of je nu kiest voor een trendy of tijdloze look, kwaliteit is altijd onze prioriteit.</p>
        <p>Liever contactlenzen? Wij helpen je graag op weg met een gratis starterskit. Zo kan je na verloop van tijd makkelijk zelf je lenzen in- en uitdoen.</p>
        <p className="text-right">
          <SocialLinks iconsOnly={true} wrapped={false} />
        </p>
      </div>
      <div className="col-start-1 md:col-start-7 lg:col-start-6 col-end-13 lg:col-end-12 row-start-1 row-end-1 z-10">
        <StaticImage
            src="../images/over-ons.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="inline-block mx-0 md:ml-8 lg:mx-2"
        />
      </div>
      <div className="section-bg-right col-start-6 md:col-start-9 lg:col-start-7 col-end-13 -top-16 -right-6 lg:right-4 h-full"></div>
    </section>

    {/* <section className="section">
      <div className="section-content-right">
        <h2 className="pb-8 md:pb-28 xl:pb-40">
          Deskundig advies
        </h2>
        <p className="text-base my-4">Wij maken ons ook sterk door u een goede service aan te bieden.  U kunt steeds rekenen op ons deskundig advies en dankzij onze recente investeringen in ons oogmeetapparatuur kunnen wij tot 25x nauwkeurigere metingen uitvoeren en u zo het best mogelijke zicht garanderen.</p>
        <p className="text-base my-4">Neem daarbij dat wij ook alle soorten contactlenzen, sportbrillen en veiligheidsbrillen kunnen leveren waardoor wij kunnen inspelen op al uw wensen.</p>
      </div>
      <div className="col-start-1 col-end-7 row-start-1 row-end-1 z-10 h-full flex justify-start items-end">
        <StaticImage
            src="../images/over-ons_1.jpg"
            formats={["AUTO", "WEBP", "AVIF"]}
            alt="Oogmeting"
            placeholder="blurred"
            className="opacity-0 hidden md:opacity-100 md:inline-block mx-2 w-8/12 h-full min-h-full"
        />
      </div>
      <div className="section-bg-left"></div>
    </section> */}
</Layout>
)

export default AboutPage
